import React from "react"
import cat1 from './assets/cats/cat_1.png'
import cat2 from './assets/cats/cat_2.png'
import munchkinCat from './assets/cats/munchkin.png'
import devonRexCat from './assets/cats/devon_rex.png'
import ragdollCat from './assets/cats/ragdoll.png'
import cat6 from './assets/cats/cat_6.png'

const IntroduceCatList: React.FC = () => {

    return (
        <div className={'introduce_cat_list_wrapper'}>
            <div className={'cat_introduce_box'}>
                <img src={cat1} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>British Shorthair</p>
                <p className={'cat_species_kor'}>브리티시 숏헤어(골드)</p>
            </div>
            <div className={'cat_introduce_box'}>
                <img src={cat2} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>British Shorthair</p>
                <p className={'cat_species_kor'}>브리티시 숏헤어(블루바이)</p>
            </div>
            <div className={'cat_introduce_box'}>
                <img src={munchkinCat} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>Munchkin</p>
                <p className={'cat_species_kor'}>먼치킨</p>
            </div>
            <div className={'cat_introduce_box'}>
                <img src={ragdollCat} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>Ragdoll</p>
                <p className={'cat_species_kor'}>랙돌</p>
            </div>
            <div className={'cat_introduce_box'}>
                <img src={cat6} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>Abyssinian</p>
                <p className={'cat_species_kor'}>아비시니안</p>
            </div>
            <div className={'cat_introduce_box'}>
                <img src={devonRexCat} alt={'cat_image'}/>
                <p className={'cat_species_eng'}>Devon Rex</p>
                <p className={'cat_species_kor'}>데본렉스</p>
            </div>
        </div>
    )
}


export default IntroduceCatList