import React from "react"
import mainLogo from './assets/mainLogo.png'
import IntroduceCatList from "./IntroduceCatList";

const SecondPage: React.FC = () => {

    return (
        <div className={'page_section'}>
            <div className={'container second_page_wrapper'}>
                <div style={{height: '400px'}}>
                    <div className={'logo'}>
                        <div className={'blue_box'}/>
                        <div className={'yellow_box'}/>
                        <img src={mainLogo} alt={'logo'}/>
                    </div>
                </div>
                <div className={'page_title'}>
                    <h1>건강한 고양이 샵 [<span>묘:하우스</span>] 입니다.</h1>
                    <div className={'subtitle'}>
                        <p>"아이들을 케어하는 정성" 을 가장 중요하게</p>
                        <p>생각하는 1:1 맞춤 케어 시스템</p>
                    </div>
                </div>
                <IntroduceCatList/>
                <div className={'store_info_wrapper'}>
                    <p>묘하우스</p>
                    <p>상호명: 묘하우스 대표자: 이혜영</p>
                    <p>주소: 충북 청주시 청원구 율량로 162 묘하우스</p>
                    <p>사업자등록번호: 751-92-01586</p>
                    <p>동물판매등록번호: 제5710000 037 2021 0031호</p>
                </div>
            </div>
        </div>
    )
}


export default SecondPage