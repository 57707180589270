import React from 'react';
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";

function App() {
    return (
        <div className="main_wrapper">
            <FirstPage/>
            <SecondPage/>
        </div>
    );
}

export default App;
