import React from "react"
import instagramLogo from './assets/sns/instagram.png'
import kkTalkLogo from './assets/sns/kkt.png'
import phoneLogo from './assets/sns/phone.png'

const SNSButtonList: React.FC = () => {

    return (
        <div className={'sns_button_list_wrapper'}>
            <button onClick={() => window.open('https://www.instagram.com/myo_house___/')}>
                <img src={instagramLogo} alt={'sns_logo'}/>
            </button>
            <button onClick={() => window.open('https://open.kakao.com/o/spGpHG7d')}>
                <img src={kkTalkLogo} alt={'sns_logo'}/>
            </button>
            <button
                onClick={() => document.location.href = 'tel:01080743931'}>
                <img src={phoneLogo} alt={'sns_logo'}/>
            </button>
        </div>
    )
}

export default SNSButtonList